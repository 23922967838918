const colors = {
  grayTrue: {
    '50': '#F1F1F1',
    '100': '#D5D5D5',
    '200': '#B9B9B9',
    '300': '#9C9C9C',
    '400': '#808080',
    '500': '#727272',
    '600': '#5B5B5B',
    '700': '#393939',
    '800': '#171717',
    '900': '#0B0B0B',
  },
  brand: {
    '50': '#FEF3F2',
    '100': '#FEE4E2',
    '200': '#FECDCA',
    '300': '#FDA29B',
    '400': '#F97066',
    '500': '#F04438',
    '600': '#D92D20',
    '700': '#B42318',
    '800': '#912018',
    '900': '#7A271A',
  },
  error: {
    '50': '#FFE7E7',
    '100': '#FFBFC0',
    '200': '#FE9FA0',
    '300': '#FE7F81',
    '400': '#FE5F61',
    '500': '#F04438',
    '600': '#D92D20',
    '700': '#B42318',
    '800': '#912018',
    '900': '#7A271A',
  },
  warning: {
    '50': '#FFFAEB',
    '100': '#FEF0C7',
    '200': '#FEDF89',
    '300': '#FEC84B',
    '400': '#FDB022',
    '500': '#F79009',
    '600': '#DC6803',
    '700': '#B54708',
    '800': '#93370D',
    '900': '#7A2E0E',
  },
  success: {
    '50': '#ECFDF3',
    '100': '#D1FADF',
    '200': '#A6F4C5',
    '300': '#6CE9A6',
    '400': '#32D583',
    '500': '#12B76A',
    '600': '#039855',
    '700': '#027A48',
    '800': '#05603A',
    '900': '#054F31',
  },
  grayBlue: {
    '25': '#FCFCFD',
    '50': '#F8F9FC',
    '100': '#EAECF5',
    '200': '#D5D9EB',
    '300': '#B3B8DB',
    '400': '#717BBC',
    '500': '#4E5BA6',
    '600': '#3E4784',
    '700': '#363F72',
    '800': '#293056',
    '900': '#101323',
  },
  green: {
    '50': '#E2F9F0',
    '100': '#B7EFDA',
    '200': '#83E5C2',
    '300': '#34DBA9',
    '400': '#00D295',
    '500': '#00C882',
    '600': '#00B776',
    '700': '#00A467',
    '800': '#00925A',
    '900': '#2B19B8',
  },
  blueLight: {
    '50': '#F0F9FF',
    '100': '#E0F2FE',
    '200': '#B9E6FE',
    '300': '#7CD4FD',
    '400': '#36BFFA',
    '500': '#0BA5EC',
    '600': '#0086C9',
    '700': '#026AA2',
    '800': '#065986',
    '900': '#0B4A6F',
  },
  violet: {
    '50': '#F5F3FF',
    '100': '#ECE9FE',
    '200': '#DDD6FE',
    '300': '#C3B5FD',
    '400': '#A48AFB',
    '500': '#875BF7',
    '600': '#7839EE',
    '700': '#6927DA',
    '800': '#5720B7',
    '900': '#491C96',
  },
  pink: {
    '50': '#FDF2FA',
    '100': '#FCE7F6',
    '200': '#FCCEEE',
    '300': '#FAA7E0',
    '400': '#F670C7',
    '500': '#EE46BC',
    '600': '#DD2590',
    '700': '#C11574',
    '800': '#9E165F',
    '900': '#851651',
  },
  orangeDark: {
    '50': '#FFF4ED',
    '100': '#FFE6D5',
    '200': '#FFD6AE',
    '300': '#FF9C66',
    '400': '#FF692E',
    '500': '#FF4405',
    '600': '#E62E05',
    '700': '#BC1B06',
    '800': '#97180C',
    '900': '#771A0D',
  },
  yellow: {
    '50': '#FEFBE8',
    '100': '#FEF7C3',
    '200': '#FEEE95',
    '300': '#FDE272',
    '400': '#FAC515',
    '500': '#EAAA08',
    '600': '#CA8504',
    '700': '#A15C07',
    '800': '#854A0E',
    '900': '#713B12',
  },
  teal: {
    '50': '#F0FDF9',
    '100': '#CCFBEF',
    '200': '#99F6E0',
    '300': '#5FE9D0',
    '400': '#2ED3B7',
    '500': '#15B79E',
    '600': '#0E9384',
    '700': '#107569',
    '800': '#125D56',
    '900': '#134E48',
  },
  cyan: {
    '50': '#ECFDFF',
    '100': '#CFF9FE',
    '200': '#A5F0FC',
    '300': '#67E3F9',
    '400': '#22CCEE',
    '500': '#06AED4',
    '600': '#088AB2',
    '700': '#0E7090',
    '800': '#155B75',
    '900': '#164C63',
  },
  black: '#000000',
  white: '#ffffff',
  icons: '#B9B9B9',
  blackAlpha: {
    '50': 'RGBA(16, 17, 18, 0.04)',
    '100': 'RGBA(16, 17, 18, 0.06)',
    '200': 'RGBA(16, 17, 18, 0.08)',
    '300': 'RGBA(16, 17, 18, 0.16)',
    '400': 'RGBA(16, 17, 18, 0.24)',
    '500': 'RGBA(16, 17, 18, 0.36)',
    '600': 'RGBA(16, 17, 18, 0.48)',
    '700': 'RGBA(16, 17, 18, 0.64)',
    '800': 'RGBA(16, 17, 18, 0.80)',
    '900': 'RGBA(16, 17, 18, 0.92)',
  },
  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#000000',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
  celo: '#FCFF52',
};

export default colors;
